import React, { Component } from "react";
class VMG extends Component {
  render() {
    return (
      <>
        <div
          class="login100-form-title"
          style={{
            backgroundImage:
              "url(https://miro.medium.com/max/3840/1*AKC97sq67ZTCOCulbEXuRw.jpeg)",
            // "url(https://martechtoday.com/wp-content/uploads/2018/08/team-teamwork-together-ss-1920_b1tngv.jpg)",
          }}
        >
          <span class="login100-form-title-1">About Us</span>
          {/* <p
            style={{
              marginBottom: "0",
              fontWeight: "700px",
              color: "#dddddd",
              fontSize: "20px",
              textAlign: "center",
              paddingBottom: "30px",
            }}
          >
            We specialize in all aspects of medical, surgical and cosmetic
            diagnosis and <br />
            treatment of skin and hair conditions. We provide dermatological
            services for all ages newborns to mature adults.
          </p> */}
        </div>
        <section id="contact" class="contact">
          <div class="container">
            <section id="about">
              <div class="container" data-aos="fade-up">
                <div class="row about-cols">
                  <div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
                    <div class="about-col">
                      <div class="img">
                        <img
                          src={require("../Images/about-mission.jpg")}
                          alt=""
                          class="img-fluid"
                        />
                        <div class="icon">
                          <img src={require("../Images/about-mission.jpg")} />
                        </div>
                      </div>
                      <h2 class="title">
                        <a href="#">Our Mission</a>
                      </h2>
                      <p>
                        AAIZOLE DERMA’s mission is to be the most trusted,
                        respected DERMA COMPANY across the globe by developing,
                        manufacturing and creating Mega Brands which shall lead
                        in their respective therapy class and will be known for
                        their Innovation, Differentiation and Quality.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
                    <div class="about-col">
                      <div class="img">
                        <img
                          src={require("../Images/about-plan.jpg")}
                          alt=""
                          class="img-fluid"
                        />
                        <div class="icon">
                          <img src="https://cdn0.iconfinder.com/data/icons/wedding-69/64/wedding_planning-paper-list-checking-checklist-512.png" />
                        </div>
                      </div>
                      <h2 class="title">
                        <a href="#">Our Plan</a>
                      </h2>
                      <p>
                        Currently, AAIZOLE DERMA is present in the domestic
                        market with simplified single division operations. Our
                        future plans are to expand domestically by having more
                        divisions to address various demographics and therapy
                        segments.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
                    <div class="about-col">
                      <div class="img">
                        <img
                          src={require("../Images/about-vision.jpg")}
                          alt=""
                          class="img-fluid"
                        />
                        <div class="icon">
                          <img src="https://cdn2.iconfinder.com/data/icons/hospital-color-1/32/Eye-512.png" />
                        </div>
                      </div>
                      <h2 class="title">
                        <a href="#">Our Vision</a>
                      </h2>
                      <p>
                        Team Aaizole derma Vision is to contribute to the world
                        to the best of our ability by creating and marketing
                        products which challenge the existing boundaries and
                        then provide much better options for those who are
                        suffering from various skin related diseases.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default VMG;
