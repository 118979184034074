import React, { Component } from "react";
class MRhome extends Component {
  constructor() {
    super();
    this.state = {
      list: null,
    };
  }  
  render() {
    return (
      <>
      <div className="navigation active">
      <ul>
        <li>
          <a href="#">
            <span className="icon"><i class="fa fa-home" aria-hidden="true"></i></span>
            <span className="title">Home</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon"><i class="fa fa-briefcase" aria-hidden="true"></i></span>
            <span className="title">Activity</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon"><i class="fa fa-home" aria-hidden="true"></i></span>
            <span className="title">Home</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon"><i class="fa fa-home" aria-hidden="true"></i></span>
            <span className="title">Home</span>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon"><i class="fa fa-heart" aria-hidden="true"></i></span>
            <span className="title">Home</span>
          </a>
        </li>
      </ul>
      </div>
      
      <div className="toggle">  
          
      </div> 
      </>
    );
  }
}
export default MRhome;
