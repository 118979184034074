import React, { Component } from "react";
import Header from "./Component/Header";
// import "./css/vendor/bootstrap/css/bootstrap.min.css";
// import "./css/vendor/icofont/icofont.min.css";
// import "./css/vendor/boxicons/css/boxicons.min.css";
// import "./css/vendor/venobox/venobox.css";
// import "./css/vendor/owl.carousel/assets/owl.carousel.min.css";
//import "./css/style2.css";
//import "./css/nav.css";
import Landing from "./Component/Landing";
import Customer from "./Component/Customer";
import Product_cat from "./Component/Product_cat";
import Footer from "./Component/Footer";
import Card from "./Component/Cards";
class Home extends Component {
  render() {
    return (
      <div>
        {/* <Header /> */}
        <Landing />

        <Product_cat />
        <Card />

        <Footer />
      </div>
    );
  }
}
export default Home;
