import React from "react";
import About from "./Third/About/About";
import Product from "./Third/Product/Product";
import Contact from "./Third/Contact/Contact";
import Trade from "./Third/Trade/Trade_in";
import Home from "./Third/Home/Home";
import Header from "./Third/Home/Component/Header";
import Login from "./Third/Home/Component/Login";
import Main from "./Third/Home/Component/Main";
import Product_det from "./Third/Product/Display";
import Dash from "./Third/Home/Component/Dash";
import { Route, Switch, Redirect } from "react-router-dom";
import Page from "./Page";
const Root = () => {
  return (
    <>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about" component={About} />
          <Route exact path="/trade" component={Trade} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/product/" component={Product} />
          <Route exact path="/details" component={Product_det} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/Main" component={Main} />
          <Route exact path="/Home" component={Main} />
          <Route exact path="/Master" component={Main} />
          <Route exact path="/Report" component={Main} />
          <Route exact path="/Contact_mail" component={Main} />
          <Route exact path="/Dash" component={Dash} />
          <Route exact path="/Dash" component={Dash} />
          <Redirect to="/" />
        </Switch>
      </div>
    </>
  );
};
export default Root;
