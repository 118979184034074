import React, { Component, useEffect } from "react";
import { Tabs } from "@yazanaabed/react-tabs";
import "../css/Display.css";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import productSubRangeInfo from "../../DataArray/ProductSubRangeInfo";
import Disp from "./Display";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Display_card extends Component {
  constructor() {
    super();
    var pName = null;
    pName = localStorage.getItem("product");

    // var newData = [];
    // var newProductName = [];
    var newData = productSubRangeInfo.filter((ob) => ob.productrange_name === pName);
    var newProductName = newData.map((ob) => ob.product_name);

    this.state = {
      sub_cat_name: "",
      sub_cat_name2: "",
      product_name: "",
      productrange_name: pName,
      newData: newData,
      newProductName: newProductName,
      activeTab: 0
    };
  }
  componentDidMount() {
    // fetch(
    //   "http://localhost:5300/product/view-product-sub-range/" +
    //     this.state.productrange_name,
    //   {
    //     method: "Post",
    //     headers: { "Content-Type": "application/json" },
    //     // body: JSON.stringify(this.state),
    //   }
    // ).then((result) => {
    //   result.json().then((res) => {
    // const filteredData = productSubRangeInfo.filter((ob) => ob.productrange_name === this.state.productrange_name);
    // this.setState({
    //   sub_cat_name2: filteredData,
    //   sub_cat_name: filteredData[0],
    //   product_name: "aarti",
    //   list: null,
    // });

    // fetch(
    //   "http://localhost:5300/product/view-product-sub-range-info/" +
    //     this.state.sub_cat_name,
    //   {
    //     method: "Post",
    //     headers: { "Content-Type": "application/json" },
    //   }
    // ).then((result) => {
    //   result.json().then((res) => {
    // const filteredData2 = filteredData.map((ob) => ob.sub_cat_name === this.state.sub_cat_name);
    // this.setState({
    //   list: filteredData2,
    // });
    //     console.log(res);
    //   });
    // });
    //   });
    // });
  }

// click(pro) {
//   fetch(
//     "http://localhost:5300/product/view-product-sub-range/" +
//       this.state.productrange_name,
//     {
//       method: "Post",
//       headers: { "Content-Type": "application/json" },
//       // body: JSON.stringify(this.state),
//     }
//   ).then((result) => {
//     result.json().then((res) => {
//       this.setState({
//         sub_cat_name2: res,
//         sub_cat_name: pro,
//         list: null,
//       });

//       fetch(
//         "http://localhost:5300/product/view-product-sub-range-info/" +
//           this.state.sub_cat_name,
//         {
//           method: "Post",
//           headers: { "Content-Type": "application/json" },
//         }
//       ).then((result) => {
//         result.json().then((res) => {
//           this.setState({
//             list: res,
//           });
//           console.log(res);
//         });
//       });
//     });
//   });
// }

render() {
  return (
    <section id="portfolio" class="portfolio">
      <div class="container">
        <div class="section-title" data-aos="fade-up">
          <h3>{this.state.productrange_name}</h3>
        </div>
        {(this.state.newData.length === 0 || this.state.newData[0].sub_cat_name === undefined) ? (
          <Disp />
        ) : (
          <div>
            <NavDropdown
              style={{
                marginLeft: "20%",
                width: "60%",
                outline: "2px solid #000",
              }}
              title={this.state.newData.length === 0 || this.state.newData[0].sub_cat_name === undefined ? ' ' : this.state.newData[0].sub_cat_name}
            >
              {this.state.newProductName.map((product, item) => (
                <NavDropdown.Item
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    this.setState({ activeTab: item })
                  }}
                >
                  {product}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </div>
        )}
      </div>
      {this.state.newData != [] ? (
        <div>
          <>
            <div style={{ fontFamily: "sans-serif", textAlign: "center" }}>
              <Tabs
                activeTab={{
                  id: this.state.activeTab,
                }}
              >
                {this.state.newData.map((product, itme) => (
                  <Tabs.Tab id={itme} title={product.product_name}>
                    <section id={product.product_name} class="about2">
                      <div class="container" style={{ textAlign: "center" }}>
                        <div class="row">
                          <div class="col-lg-6">
                            <img
                              src={product.image}
                              class="img-fluid"
                              alt=""
                            />
                          </div>
                          <div class="col-lg-6 pt-4 pt-lg-0">
                            <h3
                              style={{
                                textAlign: "left",
                              }}
                            >
                              Benefits
                            </h3>

                            <ul>
                              {product.benefits.map((ben, i2) => (
                                <li
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <i class="bx bx-check-double"></i> {ben}
                                </li>
                              ))}
                            </ul>

                            <h3
                              style={{
                                textAlign: "left",
                                marginTop: '20px',
                              }}
                            >
                              composition
                            </h3>

                            <ul>
                              {product.composition.map((ben, i2) => (
                                <li
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <i class="bx bx-check-double"></i> {ben}
                                </li>
                              ))}
                            </ul>
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: '20px',
                              }}
                              class="row icon-boxes"
                            >
                              <div class="col-md-12">
                                <i class="bx bx-receipt"></i>
                                <h4
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  Presentations
                                </h4>
                                {product.presentation.map((ben, i2) => (
                                  <p
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {ben}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Tabs.Tab>
                ))}
              </Tabs>
            </div>
            {/* <h1>{pro.product_name}</h1>
                <img src={pro.image} height="200px" width="200px" />
                <ul style={{ listStyleType: "disc" }}>
                  {pro.benefits.map((ben, i2) => (
                    <li>{ben}</li>
                  ))}
                </ul>

                <p>{pro.composition}</p>
                <p>{pro.presentation}</p>
                <p>{pro.image}</p> */}
          </>
        </div>
      ) : (
        <h2></h2>
      )}
    </section>
  );
}
}
export default Display_card;
