import React, { Component } from "react";
import team from "../../DataArray/Team";
class Trade extends Component {
  constructor() {
    super();
    this.state = {
      list: null,
    };
  }
  componentDidMount() {
    // fetch("http://localhost:5300/aboutus/view-team", {
    //   method: "Post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((response) => {
    //   response.json().then((result) => {
    //     console.log(result);
    this.setState({
      list: team,
    });
    //   });
    // });
  }
  render() {
    return (
      <div>
        {this.state.list ? (
          <div>
            <section id="team">
              <div class="container" data-aos="fade-up">
                <div class="section-title">
                  <h3>Our Team</h3>
                </div>
                <div class="row" style={{ justifyContent: 'center' }}>
                  {this.state.list.map((product, item) => (
                    <div class="col-lg-3 col-md-6">
                      <div
                        class="member"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <img
                          src={product.image}
                          class="img-fluid"
                          alt="loading"
                        />
                        <div class="member-info">
                          <div class="member-info-content">
                            <h4>{product.name}</h4>
                            <span>{product.profession}</span>
                          </div>
                        </div>
                      </div>
                    <div style={{fontSize: "20px", justifyContent:"center", display:'flex'}}>{item === 0 ? `Mr. BIPIN` : `Mr. NARESHI`}</div>
                    <div style={{fontSize: "14px", justifyContent:"center", display:'flex', fontStyle:'italic'}}>{item === 0 ? `Managing Director` : `CEO`}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <h1>please wait</h1>
        )}
      </div>
    );
  }
}
export default Trade;
