import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import Header from "../Home/Component/Header";
import Footer from "../Home/Component/Footer";
import "react-responsive-carousel/lib/styles/carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Vmg from "./Component/VMG";
import Team from "./Component/Team";
import Service from "./Component/Service";
class About extends Component {
  render() {
    return (
      <>
        <Header />
        <Vmg />
        <Team />
        <Service />
        <Footer />
      </>
    );
  }
}
export default About;
