import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import productRange from "../../DataArray/ProductRange";
class Header extends Component {
  constructor() {
    super();

    this.state = {
      list: productRange,
    };
    // fetch("http://localhost:5300/product/view-product-range", {
    //   method: "Post",
    // }).then((result) => {
    //   result.json().then((res) => {
        // this.setState({
        //   list: productRange,
        // });
        // alert(res);
  //     });
  //   });
  }

  sendfun(item_name) {
    localStorage.setItem("product", item_name);
  }
  render() {
    return (
      <Navbar bg="light" variant="light" expand="lg">
        <Navbar.Brand
          style={{
            fontSize: "30px",
            fontWeight: "700",
            letterSpacing: "1px",
            marginLeft: "120px",
          }}
        >
          <a href="/">
            <img
              // src={require("../img/logo.jpg")}
              src={require("../img/aaizolelogo.png")}
              style={{ width: 250, marginTop: -3 }}
            />
            {/* <img
              src={require("../img/NAME.png")}
              style={{ width: 100, marginTop: -7 }}
            /> */}
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ml-auto"
            style={{
              fontWeight: "600",
              float: 'right',
              letterSpacing: "1px",
            }}
          >
            <Nav.Link>
              <NavLink exact activeClassName="active_class" to="/">
                Home
              </NavLink>
            </Nav.Link>

            <Nav.Link>
              <NavLink exact activeClassName="active_class" to="/about">
                About Us
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                exact
                activeClassName="active_class"
                to="/About"
              ></NavLink>
            </Nav.Link>
            <NavDropdown
              title={
                <NavLink exact activeClassName="active_class" to="/product">
                  Product
                </NavLink>
              }
              id="basic-nav-dropdown"
            >
              {this.state.list.map((product, item) => (
                <NavDropdown.Item
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    this.sendfun(product.name);
                  }}
                  href="/details"
                >
                  {product.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            <Nav.Link>
              <NavLink exact activeClassName="active_class" to="/trade">
                Trade Inquiry
              </NavLink>
            </Nav.Link>

            <Nav.Link>
              <NavLink exact activeClassName="active_class" to="/contact">
                Contact
              </NavLink>
            </Nav.Link>
            {/* <Nav.Link>
              <NavLink exact activeClassName="active_class" to="/login">
                Login
              </NavLink>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Header;
