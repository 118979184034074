import React, { Component } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { IconContext } from "react-icons";
class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <p class="copyright">
            Show Us Some Love{" "}
            <IconContext.Provider
              value={{ color: "#aa373a", className: "global-class-name" }}
            >
              <BsFillHeartFill />
            </IconContext.Provider>{" "}
            On Social Media
          </p>
          <ul class="social">
            <li>
              <a href="#">
                <i class="bx bxl-whatsapp"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="bx bxl-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="bx bxl-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="bx bxl-youtube"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="bx bxl-skype"></i>
              </a>
            </li>
          </ul>
        </footer>
        <div className="footer-2">
          <ul class="social">
            <li>
              <a href="/">
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/about">
                <span>About</span>
              </a>
            </li>
            <li>
              <a href="/product">
                <span>Product</span>
              </a>
            </li>
            <li>
              <a href="/trade">
                <span>Trade Inquiry</span>
              </a>
            </li>
            <li>
              <a href="/contact">
                <span>Contact</span>
              </a>
            </li>
          </ul>
          <p class="copyright">©{new Date().getFullYear()}, All Rights Reserved |</p>
        </div>
        <a href="#" class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </>
    );
  }
}
export default Footer;
