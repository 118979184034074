import React, { Component } from "react";
import Header from "../Home/Component/Header";
import Footer from "../Home/Component/Footer";
import Prod from "./Component/product";
class Product extends Component {
  render() {
    return (
      <>
        <Header />
        <Prod />
        <Footer />
      </>
    );
  }
}
export default Product;
