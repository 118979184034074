import React, { Component } from "react";
import Header from "./Header";
class Landing extends Component {
  constructor() {
    super();

    this.state = {
      list: [
        {
          image: "../Cat/portfolio-1.jpg",
        },
        {
          image: "../Cat/portfolio-1.jpg",
        },
        {
          image: "../Cat/portfolio-1.jpg",
        },
        {
          image: "../Cat/portfolio-1.jpg",
        },
      ],
    };
  }
  render() {
    return (
      <>
      <Header/>
      <section id="hero">
        <div class="hero-container">
          <div
            id="heroCarousel"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

            <div class="carousel-inner" role="listbox">
              <div
                class="carousel-item active"
                style={{
                  background:
                    "url(" + require("../img/hero-bg.jpg") + ") center center ",
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  height: "80vh",
                }}
              >
                {this.state.list.map((product, i) => {})}
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Welcome to <span>Aaizole Derma</span>
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Always be good to your Skin, and your Skin will always be
                      good to you....
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="carousel-item"
                style={{
                  background:
                    "url(https://img.freepik.com/free-photo/beauty-fashion-model-with-cute-smile_157173-357.jpg?size=626&ext=jpg) center center ",
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Welcome to Aaizole Derma
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Always be good to your Skin, and your Skin will always be
                      good to you....
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="carousel-item"
                style={{
                  background:
                    "url(https://image.freepik.com/free-photo/beautiful-girl-with-perfect-clean-skin-smiling-sitting-table-beauty-spa-cosmetology_176420-9354.jpg) center center ",
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Welcome to Aaizole Derma
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Always be good to your Skin, and your Skin will always be
                      good to you....
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="carousel-item"
                style={{
                  background:
                    "url(https://image.freepik.com/free-photo/natural-beauty-portrait-female-face-body-with-perfect-skin-deodorant-advertising-hair-epilation-concept_136930-15.jpg) center center ",
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Welcome to Aaizole Derma
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Always be good to your Skin, and your Skin will always be
                      good to you....
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
                class="carousel-item"
                style={{
                  background:
                    "/product/AURAPIC_AD.mp4 center center ",
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <video autoPlay>
                  <source src='/product_photos/AURAPIC_AD.mp4' />
                </video>
              </div> */}
            </div>

            <a
              class="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon icofont-rounded-left"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon icofont-rounded-right"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
      </>
    );
  }
}
export default Landing;
