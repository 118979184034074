import * as React from "react";
import { render } from "react-dom";
import { Chart } from "react-google-charts";
import Footer from "./Footer"
import swal from "sweetalert";
// import "./assets/css/login.css";
import MRhome from "./MRHome";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
class Dash extends React.Component {
  render() {
    return (
      <Chart
      width={1000}
      height={1000}
      chartType="Calendar"
      loader={<div>Loading Chart</div>}
      data={[
        [{ type: 'date', id: 'Date' }, { type: 'number', id: 'Won/Loss' }],
        [new Date(2012, 3, 13), 0],
        [new Date(2012, 3, 14), 5],
        [new Date(2012, 3, 15), 6],
        [new Date(2012, 3, 16), 3],
        [new Date(2012, 3, 17), 4],       
      ]}
      options={{
        title: 'Performnce',
      }}
      rootProps={{ 'data-testid': '2' }}
    />
    );
  }
}
export default Dash;
