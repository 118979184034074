import React, { Component } from "react";
import { Tabs } from "@yazanaabed/react-tabs";
import "../css/Ani.css";
class Display_card extends Component {
  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="loader8">
              <div class="loader-inner"></div>
            </div>
          </div>
        </div>

        <h3 class="h3">PRODUCTS COMING SOON....</h3>
      </div>
    );
  }
}
export default Display_card;
