import React, { Component } from "react";
class Trade extends Component {
  render() {
    return (
      <section id="more-services" class="more-services">
        <div class="container">
          <div class="section-title">
            <h3>Services</h3>
          </div>
          <div class="row">
            <div class="col-md-6 d-flex align-items-stretch">
              <div
                class="card"
                style={{
                  backgroundImage:
                    "url(" + require("../Images/more-services-1.jpg") + ")",
                }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="">Management</a>
                  </h5>
                  <p class="card-text">
                    AAIZOLE DERMA management comprises of experienced PHARMA
                    PROFESSIONALS with strong leadership and value systems
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div
                class="card"
                style={{
                  backgroundImage:
                    "url(" + require("../Images/more-services-4.jpg") + ")",
                }}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="card-body">
                  <h5 class="card-title">
                    <a href="">Skilled Team</a>
                  </h5>
                  <p class="card-text">
                    We do ethical promotion through skilled professional having
                    vast experience in providing relation based customer
                    services to most respectable medical fraternity and to
                    patient through innovative range.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Trade;
