import React, { Component } from "react";
import Pdata from "./Pro_cat_arr";
import Disp from "./Display";
import productRange from "../../DataArray/ProductRange";
class Product extends Component {
  constructor() {
    super();
    this.state = {
      list: null,
      //http://localhost:5300/product/view-product-range
    };
  }
  componentDidMount() {
    // fetch("http://localhost:5300/product/view-product-range", {
    //   method: "Post",
    //   headers: { "Content-Type": "application/json" },
    // }).then((result) => {
    //   result.json().then((res) => {
    this.setState({
      list: productRange,
    });
    //     console.log(res);
    //   });
    // });
  }
  sendfun(item_name) {
    localStorage.setItem("product", item_name);
  }
  render() {
    return (
      <>
        {this.state.list ? (
          <div>
            <section id="portfolio" class="portfolio">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h3>Product Categeries</h3>
                </div>
                <div
                  class="row portfolio-container"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {this.state.list.map((product, item) => (
                    <a
                      href="/details"
                      onClick={() => {
                        this.sendfun(product.name);
                      }}
                      title="More Details"
                    >
                      <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                        <div class="portfolio-wrap">
                          <img src={product.image} class="img-fluid" alt="" />
                          <div class="portfolio-info">
                            <h4>{product.name}</h4>
                            <div class="portfolio-links" />
                          </div>
                        </div>
                      </div>
                    </a>

                  ))}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <Disp />
        )}
      </>
    );
  }
}
export default Product;
