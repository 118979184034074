import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import "./css/Contact.css";
import Header from "../Home/Component/Header";
import Footer from "../Home/Component/Footer";
import swal from "sweetalert";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qi90wzt', 'template_g16fbdq', form.current, 'tqPu1kO4JVYVwOtHi')
      .then((result) => {
        swal(
          "Sent Successfully",
          "We will try to connect you Soon!",
          "success"
        ).then((value) => {
          window.location.reload();
        });
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div>
      <Header />
      <div class="limiter">
        <div
          class="login100-form-title"
          style={{
            backgroundImage:
              "url(https://images.wallpaperscraft.com/image/telephone_handset_vintage_122355_3840x2400.jpg)",
          }}
        >
          <span class="login100-form-title-1">Contact Us</span>
        </div>
      </div>
      <section id="contact" class="contact">
        <div class="container">
          <div class="row" data-aos="fade-up">
            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div class="box">
                <div class="icon">
                  <div class="image">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div class="info">
                    <h3 class="title">Mail Us</h3>
                    <p>aaizolederma819@gmail.com</p>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div class="box">
                <div class="icon">
                  <div class="image">
                    <i class="bx bx-phone-call" aria-hidden="true"></i>
                  </div>
                  <div class="info">
                    <h3 class="title">Call Us</h3>
                    <p>(+91) 9510675767</p>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div class="box">
                <div class="icon">
                  <div class="image">
                    <i class="bx bx-map" aria-hidden="true"></i>
                  </div>
                  <div class="info">
                    <h3 class="title">Our Address</h3>
                    <p>
                      2843,Saguna Nagar,Thana Lake,Jalalpor Road, NVS.-396421
                    </p>
                  </div>
                </div>
                <div class="space"></div>
              </div>
            </div>
          </div>

          <div class="row" data-aos="fade-up">
            <div class="col-lg-6 ">
              <iframe
                class="mb-4 mb-lg-0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d931.5209536954936!2d72.90303848805372!3d20.9491489991233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjDCsDU2JzU2LjkiTiA3MsKwNTQnMTIuOSJF!5e0!3m2!1sen!2sin!4v1595936157322!5m2!1sen!2sin"
                frameborder="0"
                style={{ border: "0", width: "100%", height: "420px" }}
                allowfullscreen
              ></iframe>
            </div>

            <div class="col-lg-6">

              <form ref={form} onSubmit={sendEmail} class="php-email-form">
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      name="user_name"
                      class="form-control"
                      id="firstname"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="tel"
                    class="form-control"
                    name="number"
                    id="number"
                    pattern="^\d{3}\d{3}\d{4}$"
                    title=" Enter 10 digit Mobile Number"
                    placeholder="number"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="user_email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Message"
                    required
                  ></textarea>
                  <div class="validate"></div>
                </div>
                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Contact;
