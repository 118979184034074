import React, { Component, useRef } from "react";
import "./css/Trade.css";
import emailjs from '@emailjs/browser';
import Header from "../Home/Component/Header";
import Footer from "../Home/Component/Footer";
import swal from "sweetalert";
const Trade = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qi90wzt', 'template_8fs1a6a', form.current, 'tqPu1kO4JVYVwOtHi')
      .then((result) => {
        swal(
          "Sent Successfully",
          "We will try to connect you Soon!",
          "success"
        ).then((value) => {
          window.location.reload();
        });
      }, (error) => {
        console.log(error.text);
      });
  };
    return (
      <>
        <Header />
        <div class="limiter">
          <div
            class="login100-form-title"
            style={{
              backgroundImage:
                "url(https://img.emg-services.net/HtmlPages/HtmlPage3508/medicin-udlandet-top.jpg)",
            }}
          >
            <span class="login100-form-title-1">Trade Inquiry</span>
          </div>

          <div class="container-login100">
            <div class="wrap-login100">
              
            <form ref={form} onSubmit={sendEmail} class="php-email-form">
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      onfocus="this.value=''"
                      name="firstname"
                      class="form-control"
                      id=" firstname"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="tel"
                    class="form-control"
                    name="number"
                    id="number"
                    pattern="^\d{3}\d{3}\d{4}$"
                    title=" Enter 10 digit Mobile Number"
                    placeholder="number"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                  <div class="validate"></div>
                </div>

                <div class="form-group">
                  <textarea
                    class="form-control"
                    name="address"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Address"
                    required
                  ></textarea>
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="city"
                    id="City"
                    placeholder="City"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="state"
                    id="state"
                    placeholder=" your State"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="country"
                    id="country"
                    placeholder="Your Country"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="currentprofile"
                    id="currentprofile"
                    placeholder="Currentprofile"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="companyname"
                    id="companyname"
                    placeholder="Companyname"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="reference"
                    id="reference"
                    placeholder="Reference"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    name="dermafieldexperience"
                    id="dermafieldexperience"
                    placeholder="Derma Field Experience"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="yoursegment"
                    id="yoursegment"
                    placeholder="Your Designation"
                    required
                  />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="yourcomment"
                    id="yourcomment"
                    placeholder="Your Comment"
                    required
                  />
                  <div class="validate"></div>
                </div>

                <div class="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}
export default Trade;
