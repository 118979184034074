import React from "react";
import MiniDrawer from './Drawer'

export default function Main() {
  return (
    <div className="App">
      <MiniDrawer />
    </div>
  );
}
