import React, { Component } from "react";
import Header from "../Home/Component/Header";
import Footer from "../Home/Component/Footer";
import Disp from "./Component/product2";
class Display extends Component {
  render() {
    return (
      <>
        <Header />
        <Disp />
        <Footer />
      </>
    );
  }
}
export default Display;
