const productRange = [
    {
        name: 'COSMATICS RANGE',
        image: '/product/MEDICATED_SOAP.jpg'
    },
    {
        name: 'INJECTABLE STEROID RANGE',
        image: '/product/INJECTABLE _STEROID.jpg'
    },
    {
        name: 'ANTI SCABIES RANGE',
        image: '/product/ANTI_SCABIES.jpg'
    },
    {
        name: 'HAIR CARE RANGE',
        image: '/product/HAIR_CARE.jpg'
    },
    {
        name: 'IMMUNITY BOOSTER',
        image: '/product/IMMUNITY_BOOSTER.jpg'
    },
    {
        name: 'MEDICATED SOAP',
        image: '/product/MEDICATED_SOAP.jpg'
    },
    {
        name: 'TOPICAL STEROID RANGE',
        image: '/product/TOPICAL_STEROID.jpg'
    },
    {
        name: 'STEROID THERAPY RANGE',
        image: ''
    },
    {
        name: 'MELASMA THERAPY RANGE',
        image: ''
    },
    {
        name: 'ANTI FUNGAL RANGE',
        image: '/product/ANTI_FUNGAL.jpg'
    },
    {
        name: 'LEUDCODERMA AND WORM INFESTATION RANGE',
        image: '/product/LEUDCODERMA_AND_WORM_INFESTATION.jpg'
    },
    {
        name: 'ANTI ACNE RANGE',
        image: '/product/ANTI_ACNE.jpg'
    },
    {
        name: 'ANTI BACTERIAL RANGE',
        image: ''
    },
    {
        name: 'ANAESTHETICS RANGE',
        image: '/product/ANAESTHETIC.jpg'
    },
    {
        name: 'ANTI WRINKLES - ANTI OXIDANTS - MULTIVITAMIN - MULTIMINERALS',
        image: '/product/ANTI_WRINKLES_ANTI OXIDANTS_MULTIVITAMIN_MULTIMINERALS.jpg'
    },
    {
        name: 'ANTI ALLERGIC RANGE',
        image: "/product/ANTI_ALLERGIC.jpg"
    },
];

export default productRange;