import React from "react";
import Header from "./Third/Home/Component/Header";

function Page() {
  return (
    <>
      <section id="hero">
        <div class="hero-container">
          <div
            id="heroCarousel"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

            <div class="carousel-inner" role="listbox">
              <div
                class="carousel-item active"
                style={{
                  backgroundImage: "url(" + require("./slide-2.jpg") + ")",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Welcome to <span>Mamba</span>
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea
                      ut et est quaerat sequi nihil ut aliquam. Occaecati alias
                      dolorem mollitia ut. Similique ea voluptatem. Esse
                      doloremque accusamus repellendus deleniti vel. Minus et
                      tempore modi architecto.
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="carousel-item"
                style={{
                  backgroundImage: "url(" + require("./slide-2.jpg") + ")",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Lorem Ipsum Dolor
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea
                      ut et est quaerat sequi nihil ut aliquam. Occaecati alias
                      dolorem mollitia ut. Similique ea voluptatem. Esse
                      doloremque accusamus repellendus deleniti vel. Minus et
                      tempore modi architecto.
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="carousel-item"
                style={{
                  backgroundImage: "url(" + require("./slide-2.jpg") + ")",
                }}
              >
                <div class="carousel-container">
                  <div class="carousel-content container">
                    <h2 class="animate__animated animate__fadeInDown">
                      Sequi ea ut et est quaerat
                    </h2>
                    <p class="animate__animated animate__fadeInUp">
                      Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea
                      ut et est quaerat sequi nihil ut aliquam. Occaecati alias
                      dolorem mollitia ut. Similique ea voluptatem. Esse
                      doloremque accusamus repellendus deleniti vel. Minus et
                      tempore modi architecto.
                    </p>
                    <a
                      href="/about"
                      class="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon icofont-rounded-left"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon icofont-rounded-right"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Page;
