const productSubRangeInfo = [
    {
        benefits: ['Proven hair growth formulation for androgentic alopecia in Male & Female','Alocohol free formulation','Helps to increase blood flow of hair follicle by dilating the blood vessels','Stimulates Hair Growth in Fronto Temoral','Stimulates Hair Growth in Vertex Areas','Prolonges Anagen Phase & Enhances Hair Regrowth'],
        composition: ['Minoxidil 5% + Finasteride 0.1%'],
        presentation: ['60ML'],
        productrange_name: 'HAIR CARE RANGE',
        sub_cat_name: 'GROWFIRST',
        product_name: 'Growfirst-F',
        image: '/product_photos/GROWFIRST_F.jpg'
    },
    {
        benefits: ['Proven hair growth formulation for androgentic alopecia in Male & Female','Alocohol free formulation','Helps to increase blood flow of hair follicle by dilating the blood vessels','Stimulates Hair Growth in Fronto Temoral','Stimulates Hair Growth in Vertex Areas','Prolonges Anagen Phase & Enhances Hair Regrowth'],
        composition: ['Minoxidil 5% Topical Solution'],
        presentation: ['60ML'],
        productrange_name: 'HAIR CARE RANGE',
        sub_cat_name: 'GROWFIRST',
        product_name: 'Growfirst-M',
        image: '/product_photos/GROWFIRST_M.jpg'
    },
    {
        benefits: ['Hair Damage by sunlight','Hair loss','Androgentic Alopecia','Hair loss due to changes in hair structure','Hair supplements with all vitamins'],
        composition: ['L-Arginine 50 mg + L-Cysteine 50 mg + DL-Methionine 40 mg + L-Lysin 20 mg + Niacinamide 16 mg + Zine 12 mg + Biotin 10 mg + Inositol 10 mg + Iron 10 mg + Calcium Pantothenate 100 mg + Copper 2 mg + Selenium 40 mcg Folic Acid 2 mg'],
        presentation: ['3 Strips of 10 tabs'],
        productrange_name: 'HAIR CARE RANGE',
        sub_cat_name: 'Hairzole',
        product_name: 'Hairzole Tab.',
        image: '/product_photos/HAIRZOLE.jpg'
    },
    {
        benefits: ['Double action formula','Added advantage of moisturizer to prevent dryness','Broad spectrum anti fungle agent','Completely eliminate the P.ovale Prevents recurrence','Kills and reduces the population of the yeast which propagates dandruff','Controls flaking & seborrhoea'],
        composition: ['Ketoconazole 2% + ZPTO 1% w/v + Anti-Dandruff Topical Solution'],
        presentation: ['100ML'],
        productrange_name: 'HAIR CARE RANGE',
        sub_cat_name: 'KETOZEAL SHAMPOO',
        product_name: 'Ketozeal Shampoo',
        image: '/product_photos/KETOZEAL.jpg'
    },
    {
        benefits: ['Inhibits the activation of both Type I & II Type 5 alpha - reductase Enzyme','Decreases the level of DHT by 90% within two weeks because of longer half life','More powerful than Finasteride'],
        composition: ['Dutasteride 0.5mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'HAIR CARE RANGE',
        sub_cat_name: 'DUTAROZ',
        product_name: 'Dutaroz Tab.',
        image: '/product_photos/DUTAROZ.jpg'
    },
    {
        benefits: ['Triple action formula sebostatic exfoliating anti-inflammatory','With added advantage of hyaluronic acid for hydration','Suitable for all skin types','Minimal irritation & maximum efficacy','Alcohol free, Paraben free, Oil free'],
        composition: ['Salicylic Acid 1% Serum with Mandelic acid & Hyaluromic acid'],
        presentation: ['30g'],
        productrange_name: 'ANTI ACNE RANGE',
        sub_cat_name: 'serum',
        product_name: 'Blusera Serum',
        image: '/product_photos/BLUSERA.jpg'
    },
    {
        benefits: ['Controlled Sebum Secretion','Reduced Inflamantory Acne','Most effective therapy to be used early in severe acne'],
        composition: ['Isotretinoin soft gel caps. USP 5 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ACNE RANGE',
        sub_cat_name: 'Zetroin',
        product_name: 'Zetroin - 5 Tab.',
        image: '/product_photos/ZETROIN_5.jpg'
    },
    {
        benefits: ['Controlled Sebum Secretion','Reduced Inflamantory Acne','Most effective therapy to be used early in severe acne'],
        composition: ['Isotretinoin soft gel caps. USP 10 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ACNE RANGE',
        sub_cat_name: 'Zetroin',
        product_name: 'Zetroin - 10 Cap.',
        image: '/product_photos/ZETROIN_10.jpg'
    },
    {
        benefits: ['Controlled Sebum Secretion','Reduced Inflamantory Acne','Most effective therapy to be used early in severe acne'],
        composition: ['Isotretinoin soft gel caps. USP 20 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ACNE RANGE',
        sub_cat_name: 'Zetroin',
        product_name: 'Zetroin - 20 Tab.',
        image: '/product_photos/ZETROIN_20.jpg'
    },
    {
        benefits: ['Broad spectrum antibiotic','Additional anti-inflammatory action','Convenient dosage with added advantage of lactic acid bacillus','Reduces the development of drug-resistant bacteria'],
        composition: ['Doxycyline 100mg + Lactic Acid Bacillus 5 billion spores'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ACNE RANGE',
        sub_cat_name: 'Azodox',
        product_name: 'Azodox LB Cap.',
        image: '/product_photos/AZODOX_LB.jpg'
    },
    {
        benefits: ['Easy to use formula with insulin BD syringe','Potent anti-inflammatory action','Wide range of indications'],
        composition: ['Triamcinolone Acetonide Injection IP'],
        presentation: ['1ML'],
        productrange_name: 'INJECTABLE STEROID RANGE',
        sub_cat_name: 'Takezole',
        product_name: 'Takezole-10',
        image: '/product_photos/TAKEZOLE_10.jpg'
    },
    {
        benefits: ['Easy to use formula with insulin BD syringe','Potent anti-inflammatory action','Wide range of indications'],
        composition: ['Triamcinolone Acetonide Injection IP'],
        presentation: ['1ML'],
        productrange_name: 'INJECTABLE STEROID RANGE',
        sub_cat_name: 'Takezole',
        product_name: 'Takezole-40',
        image: '/product_photos/TAKEZOLE_40.jpg'
    },
    {
        benefits: ['Potent glucocorticoid with Anti-inflammatory','Immunosuppressive and anti allergic action','Easy dose titration'],
        composition: ['Methylprednisolone'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'STEROID THERAPY RANGE',
        sub_cat_name: 'Aaizopred',
        product_name: 'Aaizopred-4DT Tab.',
        image: ''
    },
    {
        benefits: ['Potent glucocorticoid with Anti-inflammatory','Immunosuppressive and anti allergic action','Easy dose titration'],
        composition: ['Methylprednisolone'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'STEROID THERAPY RANGE',
        sub_cat_name: 'Aaizopred',
        product_name: 'Aaizopred-8 Tab.',
        image: ''
    },
    {
        benefits: ['Potent glucocorticoid with Anti-inflammatory','Immunosuppressive and anti allergic action','Easy dose titration'],
        composition: ['Methylprednisolone'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'STEROID THERAPY RANGE',
        sub_cat_name: 'Aaizopred',
        product_name: 'Aaizopred-16 Tab.',
        image: ''
    },
    {
        benefits: ['Superior saftey profile','Suitable for longterm use','Safet chioce in paediatric and geriatric group'],
        composition: ['Deflazacort 6mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'STEROID THERAPY RANGE',
        sub_cat_name: 'Dfzole',
        product_name: 'Dfzole-6 Tab.',
        image: '/product_photos/DFZOLE_6.jpg'
    },
    {
        benefits: ['Tinea Cruris','Tinea Pedis','Tinea Unginum','Pityriasis Versicolor','Onychomycosis','Tinea Corporis'],
        composition: ['Itraconazole 100 mg'],
        presentation: ['10 Strips of 10 tabs'],
        productrange_name: 'ANTI FUNGAL RANGE',
        sub_cat_name: 'Aditral',
        product_name: 'Aditral - 100',
        image: '/product_photos/ADITRAL_100.jpg'
    },
    {
        benefits: ['Tinea Cruris','Tinea Pedis','Tinea Unginum','Pityriasis Versicolor','Onychomycosis','Tinea Corporis'],
        composition: ['Itraconazole 200 mg'],
        presentation: ['10 Strips of 10 tabs'],
        productrange_name: 'ANTI FUNGAL RANGE',
        sub_cat_name: 'Aditral',
        product_name: 'Aditral - 200',
        image: '/product_photos/ADITRAL_200.jpg'
    },
    {
        benefits: ['Dual action antifungal & antibacterial','Mainstay of treatment for fungle infection and seborrheic dermatitis','Is an effective anti septic and highly useful in topical application'],
        composition: ['Ketoconazole 2%','Cetrimide 0.5%'],
        presentation: ['75 gms pack'],
        productrange_name: 'MEDICATED SOAP',
        sub_cat_name: 'Krisol',
        product_name: 'Krisol Soap',
        image: '/product_photos/KRISOL_SOAP.jpg'
    },
    {
        benefits: ['Improve function of the human immune system','Helps to reduce the oxidative stress','Benifical in viral infection','Regulate blood pressure level','Modulates contraction inflammation and remodeling tissue and thus help to recover faster','Provides antioxidant support','Supports healthy skin'],
        composition: ['Vitamin C 500 mg + Vitamin D3 1000 IU + Ascorbic acid + Sodium ascorbate + Zinc Sulphate 50 mg + Ginger 10 mcg + Tulsi 10 mcg+ L-Selenomethionion 45 mcg'],
        presentation: ['30 Tablets'],
        productrange_name: 'IMMUNITY BOOSTER',
        sub_cat_name: 'QZEAL-C',
        product_name: 'QZEAL-C',
        image: '/product_photos/QZEAL_C.jpg'
    },
    {
        benefits: ['Double action formula','Convenient dosage','Also useful in allergic rhinits asthma','Allegic Rhinitis','Chronic Idiopethic Urticaria','Seasonal & perennial allegic','Rhinitis Eczema','Contact Dermatitis'],
        composition: ['Montelukast Sodium IP 10 mg + Levocetirizine Hydrochloride IP 5 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ALLERGIC RANGE',
        sub_cat_name: 'L - Zole',
        product_name: 'L-Zole-M',
        image: '/product_photos/L_ZOLE_M.jpg'
    },
    {
        benefits: ['Selective & Long Acting Histamine Receptors Antagonist','Highly Effective for Short & Long Term Treatment','High Bioavaliability & Rapid Onset of Action','Allegic Rhinitis','Chronic Idiopethic Urticaria','Seasonal & perennial allegic','Rhinitis Eczema','Contact Dermatitis'],
        composition: ['Levocetirizine Dihydrochloride IP 10 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ALLERGIC RANGE',
        sub_cat_name: 'L - Zole',
        product_name: 'Civok - 10',
        image: '/product_photos/CIVOK_10.jpg'
    },
    {
        benefits: ['Selective & Long Acting Histamine Receptors Antagonist','Highly Effective for Short & Long Term Treatment','High Bioavaliability & Rapid Onset of Action','Allegic Rhinitis','Chronic Idiopethic Urticaria','Seasonal & perennial allegic','Rhinitis Eczema','Contact Dermatitis'],
        composition: ['Levocetirizine Dihydrochloride IP 5 mg'],
        presentation: ['1 Strips of 10 tabs'],
        productrange_name: 'ANTI ALLERGIC RANGE',
        sub_cat_name: 'L - Zole',
        product_name: 'L-Zole 5',
        image: '/product_photos/L_ZOLE_5.jpg'
    },
    {
        benefits: ['Highly effective topical antibiotic cream','Broad spectrum action','Non resistance and safer option','Effective & Safe Topical Antibacterial'],
        composition: ['Mupirocin Cream IP 2% w/w'],
        presentation: ['5gm'],
        productrange_name: 'ANTI BACTERIAL RANGE',
        sub_cat_name: 'Mupiroz',
        product_name: 'Mupiroz Cream',
        image: '/product_photos/MUPIROZ.jpg'
    },
    {
        benefits: ['Blends of essential ingredients useful in acne','Hyperpigmentation, uneven skin','Daily clanser for all skin types'],
        composition: ['Kojic Acid + Glycolic Acid + Salicylic Acid + Vit C + Lactic Acid + Vit E + Niacinamide + Liquorice'],
        presentation: ['75g'],
        productrange_name: 'MELASMA THERAPY RANGE',
        sub_cat_name: 'Aurapic',
        product_name: 'Aurapic Face wash',
        image: '/product_photos/AURAPIC.jpg'
    },
    {
        benefits: ['1st line apporch for mealsma','Also useful in other causes of hyperpigmentation'],
        composition: ['Tretinoin 0.025% + Hydroquinone 2.0% + Fluocinolone Acetonide 0.01%'],
        presentation: ['15gm'],
        productrange_name: 'MELASMA THERAPY RANGE',
        sub_cat_name: 'MelabriK',
        product_name: 'Melabrik Cream',
        image: '/product_photos/MELABRIK.jpg'
    },
    {
        benefits: ['Blends of essential ingredients useful in acne','pigmentation, uneven skin','Daily clanser for all skin types'],
        composition: ['Kojic Acid + Glycolic Acid + Salicylic Acid + Vit C + Lactic Acid + Vit E + Niacinamide + Liquorice'],
        presentation: ['75g'],
        productrange_name: 'COSMATICS RANGE',
        sub_cat_name: 'Aurapic',
        product_name: 'Aurapic Face wash',
        image: '/product_photos/AURAPIC.jpg'
    },
    {
        benefits: ['Say No To Pain, Burning Itching Discomfort with Aaizocan','Before procedures like PRP, LASER HAIR REDUCTION, ACNE SCAR','Topical Anesthetic Cream with Applicator & Occlusion'],
        composition: ['Lidocain 2.5gm + Prilocain 2.5gm'],
        presentation: ['15gm'],
        productrange_name: 'ANAESTHETICS RANGE',
        sub_cat_name: 'Aaizocan',
        product_name: 'Aaizocan Cream',
        image: '/product_photos/AAIZOCAN.jpg'
    },
];

export default productSubRangeInfo;