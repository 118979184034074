const team = [
    {
        name: 'Mr. BIPIN',
        profession: 'Managing Director',
        gender: 'Male',
        image: "/team/bipin.jpg"
    },
    {
        name: 'Mr. NARESHI',
        profession: 'CEO',
        gender: 'Male',
        image: "/team/naresh.jpg"
    },
];

export default team;