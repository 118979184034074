import React, { Component } from "react";
class Product extends Component {
  constructor() {
    super();
    this.state = {
      Pdata: [
        {
          image: require("../img/cream.png"),
          name: "Cream",
        },
        {
          image: require("../img/soap.png"),
          name: "Soap",
        },
        {
          image: require("../img/capsules.png"),
          name: "Tablet\nCapsule",
        },
        {
          image: require("../img/hair.png"),
          name: "Hair",
        },
        {
          image: require("../img/cosmetics.png"),
          name: "Cosmetics",
        },
      ],
    };
  }
  render() {
    return (
      <div>
        <section id="what-we-do" class="what-we-do">
          <div class="container">
            <div class="section-title">
              <h2>Our Product Categery</h2>
            </div>
            <div class="row">
              {this.state.Pdata.map((product, i) => (
                <div
                  class="col-lg-2 col-md-6 icon-box"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div class="icon">
                    <img src={product.image} />
                  </div>
                  <h4 className="title">
                    <a href="">{product.name}</a>
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Product;
