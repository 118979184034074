import React, { Component } from "react";
import Customer from "./Customer";
class Card extends Component {
  constructor() {
    super();
    this.state = {
      Pdata: [
        {
          image: require("../img/team/team-1.jpg"),
          name: "Walter White",
          profection: "Chief Executive Officer",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-2.jpg"),
          name: "Sarah Jhinson",
          profection: "Product Manager",
          aknowledge:
            "Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut ",
        },
        {
          image: require("../img/team/team-3.jpg"),
          name: "William Anderson",
          profection: "CTO",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-1.jpg"),
          name: "Walter White",
          profection: "Chief Executive Officer",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-2.jpg"),
          name: "Sarah Jhinson",
          profection: "Product Manager",
          aknowledge:
            "Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut ",
        },
        {
          image: require("../img/team/team-3.jpg"),
          name: "William Anderson",
          profection: "CTO",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-1.jpg"),
          name: "Walter White",
          profection: "Chief Executive Officer",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-2.jpg"),
          name: "Sarah Jhinson",
          profection: "Product Manager",
          aknowledge:
            "Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut ",
        },
        {
          image: require("../img/team/team-3.jpg"),
          name: "William Anderson",
          profection: "CTO",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
        {
          image: require("../img/team/team-1.jpg"),
          name: "Walter White",
          profection: "Chief Executive Officer",
          aknowledge:
            " Magni qui quod omnis unde et eos fuga et exercitationem Odio veritatis perspiciatis quaerat qui aut aut aut",
        },
      ],
    };
  }
  render() {
    return (
      <>
        {this.state.Pdata ? (
          <div>
            <section id="what-we-do" class="what-we-do">
              <div class="container">
                <div class="section-title">
                  <h3>Our Customer Says</h3>
                </div>
                <div className="App">
                  <Customer autoplay interval={3000}>
                    {this.state.Pdata.map((product, item) => (
                      <div className="row">
                        <div class="col-lg-12 col-md-5 d-flex align-items-stretch">
                          <div class="member">
                            <img src={product.image} alt="" />
                            <h4>{product.name}</h4>
                            <span>{product.profection}</span>
                            <p>{product.aknowledge}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Customer>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div>no data vailable</div>
        )}
      </>
    );
  }
}
export default Card;
