import React from "react";
import Footer from "./Footer"
import swal from "sweetalert";
// import "./assets/css/login.css";
import MRhome from "./MRHome";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      companycode:""
    };
  }
  // loginfun() {
  //   console.log(this.state);
  //   fetch("http://localhost:5300/route_mr/login", {
  //     method: "Post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(this.state),
  //   }).then((result) => {
  //     result.json().then((res) => {
  //       console.log(res);
  //       if (res.is_error == false) {
  //         localStorage.setItem("username", this.state.username);
  //         swal("Successfully Logged In!!", "Welcome Back", "success").then(
  //           (value) => {
  //             window.location.replace("/Reg");
  //           }
  //         );
  //         //swal("Good job!", "You clicked the button!", "success");
  //       } else {
  //         alert("wrong");
  //       }
  //     });
  //   });
  // }

  Login = (event) => {
    alert(this.state.username);
    fetch("http://localhost:5300/route_manager/login", {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state),
    }).then((result) => {
      result.json().then((res) => {
        if (res.is_error == false) {
          swal({
      title:"Welcome To Aaizole Derma ",
      text:"",
      icon:require("../img/welcome.jpg"),
      buttons:false,
      timer:8000,
    }).then((value) => {
      window.location.replace("/Main");
    });
          // swal("Successfully Logged In!!", "Welcome Back", "success").then(
          //   (value) => {
          //     window.location.replace("/Reg");
          //   }
          // );
          //swal("Good job!", "You clicked the button!", "success");
        } else {
          alert("wrong");
        }
      });
    });
    // swal({
    //   title:"Welcome To Aaizole Derma ",
    //   text:"",
    //   icon:require("../img/welcome.jpg"),
    //   buttons:false,
    //   timer:8000,
    // }).then((value) => {
    //   window.location.replace("/Main");
    // });
  };
  render() {
    return (
      <>
      <Header/>
        <main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
          <div class="container3">
            <div class="card login-card">
              <div class="row no-gutters">
                {/* <div class="col-md-5">
                  <img
                    src={require("../Images/login.jpg")}
                    alt="login"
                    class="login-card-img"
                  />
                </div> */}
                <div class="col-md-12">
                  <div class="card-body">
                  
                    <p class="login-card-description">Sign into your account</p>
                    <form action="#!">
                      <div class="form-group ">
                        <label for="email" class="sr-only">
                          Username
                        </label>
                        <input
                          name="username"
                          onChange={(event) =>
                  this.setState({ username: event.target.value })
                }
                          id="username"
                          class="form-control"
                          placeholder="Username"
                        />
                      </div>
                      <div class="form-group ">
                        <label for="email" class="sr-only">
                          Company code
                        </label>
                        <input
                          
                          name="companycode"
                          onChange={(event) =>
                  this.setState({ companycode: event.target.value })
                }
                          id="email"
                          class="form-control"
                          placeholder="Company Code"
                        />
                      </div>
                      <div class="form-group ">
                        <label for="password" class="sr-only">
                          Password
                        </label>
                        <input
                          type="password"
                          onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                          name="password"
                          id="password"
                          class="form-control"
                          placeholder="********"
                        />
                      </div>
                      <input
                        name="login"
                        id="login"
                        type="button"
                        class="btn btn-block login-btn mb-4"
                        value="Login"
                        onClick={this.Login}
                      />
                    </form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
        
      </>
    );
  }
}

export default Login;
